import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  onBoardingStatus: EditorMobileOnboardingStatus;
  hasOnboardingCompleted?: boolean;
}

export enum EditorMobileOnboardingStatus {
  NONE = '',
  MOBILE_EDITOR = 'MOBILE_EDITOR',
  AUTOSAVE = 'AUTOSAVE',
}

const initialState: StateProps = {
  onBoardingStatus: EditorMobileOnboardingStatus.NONE,
  hasOnboardingCompleted: false,
};

const editorMobileOnboardingSlice = createSlice({
  name: 'editorMobileOnboarding',
  initialState,
  reducers: {
    startOnboarding(state) {
      state.onBoardingStatus = EditorMobileOnboardingStatus.MOBILE_EDITOR;
    },
    endOnboarding(state) {
      state.hasOnboardingCompleted = true;
      state.onBoardingStatus = EditorMobileOnboardingStatus.NONE;
    },
    toggleOnboardingState(state, action: PayloadAction<EditorMobileOnboardingStatus>) {
      state.onBoardingStatus = action.payload;
    },
  },
});

export const {startOnboarding, endOnboarding, toggleOnboardingState} = editorMobileOnboardingSlice.actions;
export const editorMobileOnboardingReducer = editorMobileOnboardingSlice.reducer;
